<template>
  <div class="right">
    <span class="spen">退换货</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Tuihuo' }" tag="li">退换货</router-link>
          <router-link :to="{ name: 'Jilu' }" tag="li">退换货记录</router-link>
          <router-link :to="{ name: 'Mingxi' }" tag="li">退款明细</router-link>
        </ul>
      </div>
      <!-- <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div> -->
      <div class="search">
        <input type="text" v-model="mes" placeholder="输入商品名称订单号" />
        <div class="fangdajing" @click="search">
          <Uimage src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="neirong" v-if="TabIndex == 0">
      <Tuihuo />
    </div>
    <div class="neirong" v-if="TabIndex == 1">
      <Jilu />
    </div>
    <div class="neirong" v-if="TabIndex == 2">
      <Mingxi />
    </div> -->
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            订单号：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar dianpu" @click="dianpu(item)">
            店铺：{{ item.storeName }}
          </div>
          <div class="biaoti mar kefu">
            <span>客服</span><Uimage src="../../../assets/order/kefu.png" />
          </div>
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu">
                {{ res.spuName }}
              </div>
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- <div class="xiangsi">找相似</div> -->
          </div>
          <div class="tousu">
            <div>投诉卖家</div>
          </div>
          
          <div class="price">
            <div class="jiage">
              ￥{{ Number(res.skuRealPrice * res.skuCount).toFixed(2) }}
            </div>
            <div class="yunfei">
              (含运费:￥{{ Number(res.freight).toFixed(2) }})
            </div>
            <div class="fangshi">在线支付</div>
          </div>
          <div class="zhuangtai">
            <div class="xiangqing" @click="ding_detail(res)">订单详情</div>
          </div>
          <div class="pay">
            <div class="liji" @click="tuikuan(res)">申请退款</div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="order_list != ''">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalNum"
          @current-change="pagechange"
          :current-page.sync="currentPage"
          v-if="totalNum != 0"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import Tuihuo from '@/components/order/kehufuwu/tuihuo.vue';
// import Jilu from '@/components/order/kehufuwu/jilu.vue';
// import Mingxi from '@/components/order/kehufuwu/mingxi.vue';
import { setContextData, getContextData } from '../../../../utils/session.js';
import { post, get } from '@/utils/request';
export default {
  // components: {
  //   Tuihuo,
  //   Jilu,
  //   Mingxi,
  // },
  data() {
    return {
      order_list: [],
      isLoading: true,
      pages: 1,
      mes: '',
      tishi: '空空如也~',
      currentPage: 1,
      totalNum: 1,
      // paihang: [
      //   {
      //     id: 1,
      //     title: '退换货',
      //   },
      //   {
      //     id: 2,
      //     title: '退换货记录',
      //   },
      //   {
      //     id: 3,
      //     title: '退款明细',
      //   },
      // ],
      // TabIndex: 0,
      // TabList: [],
    };
  },
  created() {
    this.currentPage = getContextData('currentPage7') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    //店铺
    dianpu(e) {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     storeId: e.storeId,
      //   },
      // });
       const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    ding_detail(e) {
      this.$router.push({
        name: 'Detail_dingdan',
        query: {
          //skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    pagechange(p) {
      this.currentPage = p;
      setContextData('currentPage7', this.currentPage);
      get(
        'api/orderbasic/getOrderByMemberId?orderStatus=4&pageNum=' +
          p +
          '&pageSize=10'
      ).then((res) => {
        this.order_list = res.data.data.list;
      });
    },
    getorder(e) {
      get(
        'api/orderbasic/getOrderByMemberId?orderStatus=4&pageNum=' +
          e +
          '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        this.order_list = res.data.data.list;
        this.totalNum = res.data.data.total;
      });
    },
    search() {
      this.isLoading = true;
      get(
        'api/orderbasic/getOrderByMemberId?orderStatus=4&orderNo=' + this.mes
      ).then((res) => {
        this.isLoading = false;
        if (res.data.data.list == '') {
          this.tishi = '未查到相关订单~';
          this.order_list = res.data.data.list;
        } else {
          this.order_list = res.data.data.list;
          this.totalNum = res.data.data.total;
        }
      });
    },
    tuikuan(e) {
      this.$router.push({
        name: 'Tuihuosq',
        params: {
          skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    // selectedTabItem(index, e) {
    //   this.TabIndex = index;
    //   this.mes = '';
    // },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .qiehuan {
    //   height: 80px;
    //   display: flex;
    //   align-items: center;
    //   cursor: default;
    //   .daohang {
    //     font-size: 15px;
    //     color: #272727;
    //     margin-right: 25px;
    //     cursor: pointer;
    //   }
    //   .bian {
    //     color: #1a4fff;
    //   }
    // }
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;

      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 250px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    .search {
      margin-right: 150px;
      display: flex;
      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }
      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .neirong {
    width: 975px;
    margin-top: 13px;
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    margin-top: 30px;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    width: 100%;
    height: 137px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      .title {
        width: 240px;
        padding-top: 34px;
        display: flex;
        .miaoshu {
          color: #333;
          width: 190px;
          height: 40px;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .shuliang {
          margin-left: 10px;
        }
      }
      .xiangsi {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 10px;
      }
    }
    .tousu {
      width: 102px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      padding-top: 34px;
      font-size: 14px;
      color: #333;
    }
    .price {
      width: 158px;
      border-right: 1px solid #eaeaea;
      padding-top: 34px;
      padding-left: 25px;
      .jiage {
        font-size: 14px;
        color: #333;
      }
      .yunfei {
        color: #a0a0a0;
        font-size: 14px;
      }
      .fangshi {
        color: #a0a0a0;
        font-size: 14px;
      }
    }
    .zhuangtai {
      width: 130px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #333;
      .xiangqing {
        margin-top: 5px;
        cursor: pointer;
      }
      .wuliu {
        color: #dc7413;
        margin-top: 5px;
      }
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      text-align: center;
      .liji {
        color: #fff;
        background: #3560fd;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}
</style>
